<template>
    <div class="page">
        <div class="filter">
           
            <div class="handle" @click="startTimePopup = true">
                <span>选择日期</span>
                <div class="value" v-if="startTime"> {{startTime}}</div>
                <div class="placehoder" v-else>选择开始时间</div>
                <i />
                <div class="value" v-if="endTime"> {{endTime}}</div>
                <div class="placehoder" v-else>选择结束时间</div>
                <img src="./../../assets/images/integral/arrow.png" alt="">
            </div>
            
            <div class="button">查询统计</div>
        </div>
    
        
        <div class="list">
            <div class="item">
                <div class="title">学校名称：长郡雨外洪塘学校小学部</div>
                <div class="goods-list">
                    <div class="goods-item">
                        <div class="name">长郡雨外洪塘学校小学部新生班服（二件)男女同款</div>
                        <div class="num">数量:<span>&nbsp;&nbsp;1000</span></div>
                    </div>
                    <div class="goods-item">
                        <div class="name">长郡雨外洪塘学校小学部冬裤</div>
                        <div class="num">数量:<span>&nbsp;&nbsp;999</span></div>
                    </div>
                    <div class="goods-item" v-for="item in 20">
                        <div class="name">长郡雨外洪塘学校小学部冬季棉衣</div>
                        <div class="num">数量:<span>&nbsp;&nbsp;666</span></div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="buttonGroup">
            <van-button type="primary" plain color="#FF553F" @click="goList">按照年级查看校服订购详情</van-button>
            <van-button type="primary" color="#FF553F" @click="submit">确认数据发送给厂家生产</van-button>
            <div class="tips">数据发送到厂家后，厂家按照此数据进行生产</div>
        </div>
    
        <van-popup v-model="startTimePopup" round position="bottom">
            <van-datetime-picker
                type="date"
                title="选择开始时间"
                :max-date="maxDate"
                :formatter="formatter"
                @confirm="onStartConfirm"
            />
        </van-popup>
    
        <van-popup v-model="endTimePopup" round position="bottom">
            <van-datetime-picker
                type="date"
                title="选择结束时间"
                :min-date="startTimeStack"
                :max-date="maxDate"
                :formatter="formatter"
                @confirm="onEndConfirm"
            />
        </van-popup>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "statistical",
    data() {
        return {
            startTimePopup: false,
            endTimePopup: false,
            monthPopup: false,
            maxDate: new Date(),
            startTimeStack: '',
    
           
            startTime: '',
            endTime: '',
        }
    },
    methods: {
        formatter(type, val) {
            if (type === 'year') {
                return `${val}年`;
            } else if (type === 'month') {
                return `${val}月`;
            }else if (type === 'day') {
                return `${val}日`;
            }
            return val;
        },
        
        onMonthConfirm(val) {
            // val => {text: 1月, value: 1}
            this.month = val.text;
            this.monthPopup = false
        },
        
        onStartConfirm(val) {
            this.startTimeStack = val;
            this.startTimePopup = false;
            this.endTimePopup = true
        },
        onEndConfirm(val) {
            let date = new Date(this.startTimeStack)
            this.startTime = moment(date).format('YYYY-MM-DD')
            
            let endDate = new Date(val);
            this.endTime = moment(endDate).format('YYYY-MM-DD')
            this.endTimePopup = false
        },
    
        goList() {
            this.$router.push('/management/grade')
        },
    },
    
    
    mounted() {
        let date = new Date();
        this.endTime = moment(date).format('YYYY-MM-DD')
        date.setMonth(date.getMonth() - 1);
        this.startTime =  moment(date).format('YYYY-MM-DD')
    }
    
}
</script>

<style scoped lang="less">
.page {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: hidden;
}
.filter {
    height: 38px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .button {
        width: 64px;
        height: 25px;
        background: #45C579;
        border-radius: 5px;
        
        color: #fff;
        font-size: 12px;
        text-align: center;
        line-height: 25px;
    }
}
.handle {
    display: flex;
    align-items: center;
    span {
        font-size: 10px;
        color: #999;
        margin-right: 10px;
    }
    i {
        width:10px;
        height: 1px;
        background-color: #999;
        display: inline-block;
        margin: 0 5px;
    }
    div {
        font-size: 10px;
        &.placehoder {
            color: #999;
        }
        
        &.value {
            color: #212121;
        }
    }
    img {
        height: 5px;
        width: 8px;
        margin-left: 7px;
    }
}


.list {
    flex: 1;
    overflow-y: auto;
    .item {
        .title {
            padding: 15px 29px 10px;
            line-height: 12px;
            font-size: 12px;
            font-weight: 500;
            color: #212121;
            background-color: #f4f4f4;
        }
        
        .goods-list {
            padding: 0 28px;
            
            .goods-item {
                padding: 10px 0;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                border-bottom: 1px solid #EEEDEC;
                .name {
                    font-size: 12px;
                    color: #212121;
                    line-height: 20px;
                    width: 220px;
                    word-break: normal;
                }
                
                .num {
                    font-size: 10px;
                    color: #999999;
                    line-height: 20px;
                    span {color: #f00;}
                }
            }
        }
    }
}

.buttonGroup {
    padding: 20px 28px;
    background-color: #fff;
   
    .van-button {
        width: 100%;
        &+.van-button {
            margin-top: 10px;
        }
    }
    
    .tips {
        line-height: 11px;
        font-size: 10px;
        color: #BDBDBD;
        margin-top: 10px;
        text-align: center;
    }
}
</style>
